import "./index.scss";
import Gumshoe from "gumshoejs";
import "aos/dist/aos.css";
import * as aos from "aos/dist/aos.js";

aos.init();

var header = document.querySelector("#app-header");
if (header)
  new Gumshoe("#app-awesome-nav a", {
    nested: true, // if true, add classes to parents of active link
    // Offset & reflow
    // how far from the top of the page to activate a content area
    offset: 600,
    reflow: false, // if true, listen for reflows

    // Event support
    events: false, // if true, emit custom events
  });
